// Library Utilities
import React from "react";
import { Link } from "react-router-dom";

// Styles
import Styles from "./trialButton.module.scss";

function TrialButton(props: any) {
  return (
    <Link
      id={props.id}
      style={props.style}
      to={props.linkTo}
      className={Styles.tryNowButton}
    >
      {props.children}
    </Link>
  );
}

export default TrialButton;
