import React, { useEffect } from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import {
  validBankAccountNumber,
  validIFSCNumber,
  validNameCheck,
} from "../../../../../utils/validation";
import { IdsGenerator } from "../../../../../utils/idGenerator";

function BankAccountVerificationAdvancePopup({ handleResponse, name }: any) {
  console.log(name);

  const errorObject = {
    accountNumberError: false,
    ifscNumberError: false,
    nameToMatchError: false,
    consentError: false,
    nameEmptyError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isNameMatchEnabled, setIsNameMatchEnabled] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [pennyDropChecked, setPennyDropChecked] = React.useState(true);

  const accountNumberRef = React.useRef<any>("");
  const ifscNumberRef = React.useRef<any>("");
  const nameMatchRef = React.useRef<any>("");

  function isValid() {
    if (isNameMatchEnabled) {
      if (
        accountNumberRef.current.value &&
        ifscNumberRef.current.value &&
        nameMatchRef.current.value &&
        Object.values(error).every((value) => value === false)
      ) {
        return setIsDisabled(false);
      }
    }

    if (!isNameMatchEnabled) {
      if (
        accountNumberRef.current.value &&
        ifscNumberRef.current.value &&
        Object.values(error).every((value) => value === false)
      ) {
        return setIsDisabled(false);
      }
    }

    return setIsDisabled(true);
  }

  useEffect(() => {
    if (isNameMatchEnabled) {
      setIsDisabled(true);
    }
    if (!isNameMatchEnabled) {
      setError(() => ({
        ...error,
        nameToMatchError: false,
      }));
      if (accountNumberRef && ifscNumberRef) {
        setIsDisabled(false);
      }
    }
  }, [isNameMatchEnabled]);

  useEffect(() => {
    isValid();
  }, [
    accountNumberRef,
    ifscNumberRef,
    nameMatchRef,
    isNameMatchEnabled,
    error,
  ]);

  const handleChange = (event: any) => {
    setPennyDropChecked(event.target.value === "true");
  };

  function checkAccountNumberValidation() {
    if (
      !validBankAccountNumber(
        accountNumberRef.current.value.trim().toUpperCase()
      )
    ) {
      setError(() => ({
        ...error,
        accountNumberError: true,
      }));
    } else {
      setError(() => ({
        ...error,
        accountNumberError: false,
      }));
    }
  }

  function checkIfscNumberValidation() {
    if (!validIFSCNumber(ifscNumberRef.current.value.trim().toUpperCase())) {
      setError(() => ({
        ...error,
        ifscNumberError: true,
      }));
    } else {
      setError(() => ({
        ...error,
        ifscNumberError: false,
      }));
    }
  }

  function isValidNameMatch() {
    setError({ ...errorObject });
    if (
      validBankAccountNumber(accountNumberRef.current.value.trim()) &&
      validIFSCNumber(ifscNumberRef.current.value.trim().toUpperCase()) &&
      validNameCheck(nameMatchRef.current.value)
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    if (!validNameCheck(nameMatchRef.current.value)) {
      setError(() => ({
        ...error,
        nameToMatchError: true,
      }));
    }
    return setIsDisabled(true);
  }

  function submitVerificationRequest() {
    const accountNumber = accountNumberRef.current.value.toUpperCase();
    const ifscNumber = ifscNumberRef.current.value.toUpperCase();
    const name_to_match = nameMatchRef.current?.value;
    const is_penny_drop = pennyDropChecked;

    const consent = isChecked === true ? "Y" : "N";

    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    handleResponse(
      { accountNumber, ifscNumber, name_to_match, consent, is_penny_drop },
      setLoading
    );
  }

  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-2">
            <label className={`${Styles.label} flex-grow-1`}>
              Account Number
            </label>
            <InputField
              maxLength={16}
              id={IdsGenerator({
                prefix: "bank account varification advance",
                id: "change",
                sufix: "account number",
              })}
              inputRef={accountNumberRef}
              onChange={checkAccountNumberValidation}
              isError={error.accountNumberError}
              purpose="forBankVerification"
            />
            {error.accountNumberError && (
              <ErrorMessage>Please enter a valid Account number</ErrorMessage>
            )}
          </div>
          <div className="d-flex flex-column mb-3 ">
            <label className={`${Styles.label} flex-grow-1`}>IFSC Number</label>
            <InputField
              id={IdsGenerator({
                prefix: "bank account varification advance",
                id: "change",
                sufix: "ifsc number",
              })}
              onChange={checkIfscNumberValidation}
              maxLength={11}
              inputRef={ifscNumberRef}
              isError={error.ifscNumberError}
              purpose="forBankVerification"
            />
            {error.ifscNumberError && (
              <ErrorMessage>Please enter a valid IFSC number</ErrorMessage>
            )}
          </div>
          <div className="pl-4 pb-2 d-flex flex-column">
            <div>
              <input
                id={IdsGenerator({
                  prefix: "bank account varification advance",
                  id: "check",
                  sufix: "is name match",
                })}
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => setIsNameMatchEnabled(e.target.checked)}
              />
              <p className={`${Styles.label} flex-grow-1`}>
                Is name match enabled?
              </p>
            </div>
          </div>
          {isNameMatchEnabled && (
            <div className="d-flex flex-column mb-2 ">
              <label className={`${Styles.label} flex-grow-1`}>
                Name To Match
              </label>
              <InputField
                id={IdsGenerator({
                  prefix: "bank account varification advance",
                  id: "change",
                  sufix: "is name match",
                })}
                onChange={isValidNameMatch}
                inputRef={nameMatchRef}
                isError={error.nameToMatchError}
                purpose="forBankVerification"
                text="lowerCase"
              />
              {error.nameToMatchError && (
                <ErrorMessage>Please enter a valid Name</ErrorMessage>
              )}
            </div>
          )}
          <>
            <label className={`${Styles.label} flex-grow-1 mt-2`}>
              Penny Drop
            </label>
            <div className="d-flex mt-1">
              <label className="mr-2 mb-0">
                <input
                  id={IdsGenerator({
                    prefix: "bank account varification advance",
                    id: "select true",
                    sufix: "penny drop",
                  })}
                  type="radio"
                  value="true"
                  checked={pennyDropChecked === true}
                  onChange={handleChange}
                  className="mr-1"
                  style={{ cursor: "pointer" }}
                />
                True
              </label>

              <label className="mb-0">
                <input
                  id={IdsGenerator({
                    prefix: "bank account varification advance",
                    id: "select false",
                    sufix: "penny drop",
                  })}
                  type="radio"
                  value="false"
                  checked={pennyDropChecked === false}
                  onChange={handleChange}
                  className="mr-1"
                  style={{ cursor: "pointer" }}
                />
                False
              </label>
            </div>
          </>
          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                id={IdsGenerator({
                  prefix: "bank account varification advance",
                  id: "check",
                  sufix: "consent",
                })}
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => setIsChecked(e.target.checked)}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id={IdsGenerator({
                prefix: "bank account varification advance",
                id: "click",
                sufix: "run verification",
              })}
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                submitVerificationRequest();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankAccountVerificationAdvancePopup;
