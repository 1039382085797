import React, { useEffect } from "react";

// components
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";

// Styles
import Styles from "../../Shared/sharedStyles.module.scss";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";

// Constants
import { MEDIUM } from "../../../../../Shared/buttonSize";

// Functions
import {
  validNameCheck,
  validVoterNumber,
} from "../../../../../utils/validation";
import { IdsGenerator } from "../../../../../utils/idGenerator";

function VoterServicePopup({ handleResponse, name }: any) {
  const errorObject = {
    epicNumberError: false,
    consentError: false,
    nameToMatchError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const nameMatchRef = React.useRef<any>("");
  const [isNameMatchEnabled, setIsNameMatchEnabled] = React.useState(false);

  const epicNumberRef = React.useRef<any>("");

  useEffect(() => {
    if (isNameMatchEnabled) {
      setIsDisabled(true);
    }
    if (!isNameMatchEnabled) {
      if (epicNumberRef.current.value) {
        setIsDisabled(false);
      }
    }
  }, [isNameMatchEnabled]);

  function isValidNameMatch() {
    setError({ ...errorObject });
    if (
      validVoterNumber(epicNumberRef.current.value.trim().toUpperCase()) &&
      validNameCheck(nameMatchRef.current.value)
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    if (!validVoterNumber(epicNumberRef.current.value.trim().toUpperCase())) {
      setError(() => ({
        ...error,
        panNumberError: true,
      }));
    }
    if (!validNameCheck(nameMatchRef.current.value)) {
      setError(() => ({
        ...error,
        nameToMatchError: true,
      }));
    }
    return setIsDisabled(true);
  }

  function isValid() {
    if (validVoterNumber(epicNumberRef.current.value.trim().toUpperCase())) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    setError(() => ({
      ...error,
      epicNumberError: true,
    }));
    return setIsDisabled(true);
  }

  function handleCheck() {
    const epicNumber = epicNumberRef.current.value.toUpperCase();
    if (isNameMatchEnabled && nameMatchRef.current.value === "") {
      setError(() => ({
        ...error,
        nameToMatchError: true,
      }));
      return setIsDisabled(true);
    }

    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }

    const consent = isChecked === true ? "Y" : "N";
    setLoading(true);
    if (nameMatchRef && nameMatchRef.current && nameMatchRef.current.value) {
      const nameToMatch = nameMatchRef.current.value;
      handleResponse({ epicNumber, nameToMatch, consent }, setLoading);
    } else if (!isNameMatchEnabled) {
      handleResponse({ epicNumber, consent }, setLoading);
    }
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>EPIC Number</label>
            <InputField
              id={IdsGenerator({
                prefix: "voter service popup",
                id: "change",
                sufix: "EPIC Number",
              })}
              maxLength={10}
              onChange={isValid}
              inputRef={epicNumberRef}
              isError={error.epicNumberError}
              purpose="forVoter"
            />
            {error.epicNumberError && (
              <ErrorMessage>Epic number is not valid</ErrorMessage>
            )}
          </div>

          <div className="pl-4 pb-2 pt-4 d-flex flex-column">
            <div>
              <input
                id={IdsGenerator({
                  prefix: "voter service popup",
                  id: "check",
                  sufix: "is name match required",
                })}
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => setIsNameMatchEnabled(e.target.checked)}
              />
              <p className={`${Styles.ackText}`}>is name match required ??</p>
            </div>
          </div>
          {isNameMatchEnabled && (
            <div className="d-flex flex-column mb-4 ">
              <label className={`${Styles.label} flex-grow-1`}>
                Name To Match
              </label>
              <InputField
                id={IdsGenerator({
                  prefix: "voter service popup",
                  id: "change",
                  sufix: "name match",
                })}
                onChange={isValidNameMatch}
                inputRef={nameMatchRef}
                isError={error.nameToMatchError}
                purpose="forPassport"
                text="lowerCase"
              />
              {error.nameToMatchError && (
                <ErrorMessage>Please enter a valid Name</ErrorMessage>
              )}
            </div>
          )}
          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                id={IdsGenerator({
                  prefix: "voter service popup",
                  id: "check",
                  sufix: "consent",
                })}
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => {
                  setIsChecked(e.target.checked);
                  isNameMatchEnabled ? isValidNameMatch() : isValid();
                }}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id={IdsGenerator({
                prefix: "voter service popup",
                id: "click",
                sufix: "run verification",
              })}
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoterServicePopup;
