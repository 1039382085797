import React, { useEffect, useRef } from "react";
import otpVerificationNew from "../../../assets/images/otpVerificationNew.png";
import { MEDIUM } from "../../buttonSize";

// Components
import Button from "../../button/button";
import ErrorMessage from "../../error-message/errorMessage";

// Styles
import Styles from "./haveZpin.module.scss";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../buttonStyles";

// Functions
import { checkIsEmpty, isValidZpin } from "../../../utils/validation";

function HaveZpin({
  updateZpinFlow,
  onCancel,
  otpFlowInstead,
  isLoading,
  handleZpinAuthHave,
}: any) {
  const lengthOfZpin = 4;
  const [zpin, setZpin] = React.useState(new Array(lengthOfZpin).fill(""));
  const inputRef = useRef<any>([]);
  const [error, setError] = React.useState("");
  const [validZpin, setValidZpin] = React.useState(false);
  const [invalidZpin, setInvalidZpin] = React.useState(false);
  const [wrongNumberOfAttempts, setWrongNumberOfAttempts] = React.useState(0);

  useEffect(() => {
    inputRef.current[0].focus();
  }, []);

  function validateZpin(e: any) {
    e.preventDefault();
    if (checkIsEmpty(zpin.join(""))) {
      setError("ZPIN cannot be empty");
      return setInvalidZpin(true);
    }
    handleZpinAuthHave(zpin.join(""), null, setInvalidZpinError);
  }

  const handleChange = (index: any, e: any) => {
    const value = e.target.value;
    setInvalidZpin(false);
    setError("");

    // validation for the input ( should be only numbers )
    if (value !== "" && !isValidZpin(value)) {
      inputRef.current[index].value = "";
      setError("ZPIN should be numeric");
      return setInvalidZpin(true);
    }

    const newZpin = [...zpin]; // taking copy as set useState is asynchronous

    // allow only one input and that is last input
    newZpin[index] = value.substring(value.length - 1);
    // TODO: disable input field

    setZpin(newZpin);

    // enable submit button
    const joinedZpin = newZpin.join(""); // we convert array to string cuz array length will always be 4 even if the index is empty
    if (joinedZpin.length === lengthOfZpin) {
      setValidZpin(true);
    } else {
      setValidZpin(false);
    }

    // move to next input field if current field is filled
    if (value && index < lengthOfZpin - 1 && inputRef.current[index + 1]) {
      // Keep this commented this will help you to understand the logic

      // if (zpin[index + 1] === "") {
      //   // if next valur is empty
      //   inputRef.current[index + 1].focus();
      // } else {
      //   // else find the first empty field
      //   inputRef.current[newZpin.indexOf("")].focus();
      // }

      inputRef.current[newZpin.indexOf("")].focus();
    }

    return;
  };

  const handleKeyDown = (index: any, e: any) => {
    if (e.key === "Tab") {
      e.preventDefault(); // stop normal working of the tab key
      return;
    }

    if (
      e.key === "Backspace" && //key pressed is backspace
      !zpin[index] && // if their is no value in current input field
      index > 0 && // current index should not be first index
      inputRef.current[index - 1] // last index exist of not in the zpin
    ) {
      // TODO: make current input enable

      // move focus to the previous field on backspace
      inputRef.current[index - 1].focus();
    }
  };

  const handleClick = (index: any, e: any) => {
    e.preventDefault();
    // TODO: first enable the input field and then update it or you can't do this operation

    inputRef.current[index].setSelectionRange(1, 1); // select the value of the input field
    // we have only one number and that is at 0 index so we select (1, 1) to put the cursor ahead of it

    // if you click ahead input field before filling previous input field jump back to first empty input field
    if (index > 0 && !zpin[index - 1]) {
      inputRef.current[zpin.indexOf("")].focus(); // jump to first empty input field
    }
  };

  function setInvalidZpinError() {
    if (wrongNumberOfAttempts === 2) {
      return otpFlowInstead(true);
    }

    setWrongNumberOfAttempts(wrongNumberOfAttempts + 1);
    setInvalidZpin(true);
    setError("Invalid zpin, please re-enter");
    resetAllInputs();
  }

  function resetAllInputs() {
    for (let i = 0; i < lengthOfZpin; i++) {
      inputRef.current[i].value = "";
      // currentInput.disabled = false;
    }

    setZpin(new Array(lengthOfZpin).fill(""));
    inputRef.current[0].focus();
  }

  return (
    <>
      <form>
        <div className="py-3 text-center">
          <img
            src={otpVerificationNew}
            alt="otp-verification"
            style={{ width: "100px" }}
          />
        </div>
        <div className="py-2 px-4">
          <p className={`mb-0 ${Styles.headingText}`}>Enter 4-digit ZPIN</p>
        </div>
        <div style={{ width: "300px", margin: "0 auto" }} className="py-3">
          <div
            className={`py-2 d-flex align-items-center justify-content-center ${
              invalidZpin ? "wrongOtp" : ""
            }`}
          >
            {zpin.map((value: any, index: number) => {
              return (
                <input
                  key={index}
                  ref={(input) => (inputRef.current[index] = input)}
                  id={`z-pin-existing-${index}`}
                  type="password"
                  value={value}
                  className={`${Styles.commonInputClass} ${
                    Styles.formControl
                  } ${invalidZpin ? Styles.wrongOtp : ""}`}
                  style={{ width: "40px", top: 50, left: 50 }}
                  onClick={(e) => handleClick(index, e)}
                  onChange={(e) => handleChange(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                />
              );
            })}
          </div>
          <div className="px-1 d-flex align-items-center justify-content-center flex-column">
            {error && <ErrorMessage forPopup={true}>{error}</ErrorMessage>}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center px-4">
          <div className="px-2">
            <Button
              type="submit"
              id="submit-have-zpin"
              hoveredStyle={primaryButtonHoverStylePopup}
              style={primaryButtonStylePopup}
              isLoading={isLoading}
              disabled={!validZpin}
              onClick={validateZpin}
              size={MEDIUM}
            >
              Submit
            </Button>
          </div>
        </div>

        <div className="d-flex flex-column align-items-center justify-content-center">
          <p className={`${Styles.subText} flex-grow-1`}>
            Forgot ZPIN?
            <span
              id="create-new-zpin"
              className={Styles.createZpinLink}
              onClick={() => {
                updateZpinFlow();
              }}
            >
              {" "}
              Create New
            </span>
          </p>

          <p
            id="use-otp-authentication"
            className={`${Styles.forOTPAuth} flex-grow-1`}
            onClick={otpFlowInstead}
          >
            Use OTP for Authentication
          </p>
        </div>

        <div className="py-3 d-flex justify-content-center">
          <div className="d-inline-flex">
            <p
              id="cancel-have-zpin"
              className={`mb-0 ${Styles.cancelText}`}
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </p>
          </div>
        </div>
      </form>
    </>
  );
}

export default HaveZpin;
