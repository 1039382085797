// Library Utilities
import React, { useEffect, useRef } from "react";
// import Styles from "../../Shared/popup.module.scss";

// Constants
import otpVerificationNew from "../../../assets/images/otpVerificationNew.png";
import { MEDIUM } from "../../buttonSize";

// Components
import Button from "../../button/button";
import ErrorMessage from "../../error-message/errorMessage";

// Styles
import Styles from "./otpVerificationPopUp.module.scss";
import {
  disabledButtonHoverStylePopup,
  disabledButtonStylePopup,
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../buttonStyles";

// Functions
import { checkIsEmpty, isValidOtp } from "../../../utils/validation";

function OtpVerificationPopUp({
  toggleResetOtp,
  getOtp,
  isLoading,
  onCancel,
  resendOtp,
  forZpin = false,
}: any) {
  const registeredEmail = localStorage.getItem("email");
  const lengthOfOtp = 6;
  const [otp, setOtp] = React.useState("");
  const inputs: any = Array(lengthOfOtp)
    .fill(null)
    .map((_, index) => index);
  let currentActiveInput = 0;
  const inputRef = useRef<any>([]);
  const [error, setError] = React.useState("");
  const [validOtp, setValidOtp] = React.useState(false);
  const [isInvalid, setIsInvalid] = React.useState(false);
  const [resendOTPCount, setResendOTPCount] = React.useState(0);
  const [timer, setTimer] = React.useState(0);

  useEffect(() => {
    // reset the otp in case of any error occurred
    if (toggleResetOtp) {
      resetAllInputs();
    }
  }, [toggleResetOtp]);

  function validateOtp(e: any) {
    e.preventDefault();
    if (checkIsEmpty(otp)) {
      setError("Otp cannot be empty");
      return setIsInvalid(true);
    }
    // setToggleResetOtp(false);
    getOtp(null, otp, setInvalidOTPError);
  }

  function setInvalidOTPError() {
    setIsInvalid(true);
    setError("OTP Is not valid, please re-enter");
    resetAllInputs();
    setTimeout(() => {
      setIsInvalid(false);
      setError("");
    }, 2000);
  }

  function handleOtp(otpValue: string, index: number) {
    // To check whether OTP is numeric or not
    if (!isValidOtp(otpValue) && otpValue !== "") {
      inputRef.current[index].value = "";
      setError("OTP should be numeric");
      setIsInvalid(true);
      return;
    }
    // To check whether the user is removing the entered OTP.
    if (otpValue === "") {
      currentActiveInput = index - 1;
      const currentInput = inputRef.current[currentActiveInput];
      // To check whether the focused input is not less than 0
      if (currentActiveInput < 0) {
        return;
      }
      // To check whether the user is clearing the last input
      // if clears the last input than we remove two values else one
      if (otp.length === lengthOfOtp) {
        // const nextInput = inputRef.current[currentActiveInput + 1];
        const str = otp.substr(0, otp.length - 2);
        setOtp(str);
      } else {
        const str = otp.substr(0, otp.length - 1);
        setOtp(str);
      }
      // Remove disable state.
      currentInput.disabled = false;
      currentInput.value = "";
    } else {
      // Only accepts 6 digits in otp
      if (otp.length > lengthOfOtp - 1) {
        return;
      }
      setOtp((otp: string) => `${otp + otpValue}`);
      const currentInput = inputRef.current[index];
      // Add disable state once input is filled.
      otp.length < lengthOfOtp - 1 && (currentInput.disabled = true);
      currentActiveInput = index + 1;
    }
    // To check the verify button enable and disable
    if (otp.length === lengthOfOtp - 1) {
      setValidOtp(true);
    } else {
      setValidOtp(false);
    }
    setError("");
    const nextInput = inputRef.current[currentActiveInput];
    // To Check whether the focus does not exceed the number of input
    // else throw error of index out of bound
    if (currentActiveInput < lengthOfOtp && currentActiveInput >= 0) {
      nextInput.focus();
    }
  }

  // reset all input values in case the user selects reset password or any error occurred
  function resetAllInputs() {
    let i = 0;
    while (i < lengthOfOtp) {
      const currentInput = inputRef.current[i];
      currentInput.value = "";
      currentInput.disabled = false;
      i++;
    }
    setOtp("");
    setValidOtp(false);
    inputRef.current[currentActiveInput].focus();
  }

  function handleResendOtp() {
    resetAllInputs();
    // Call API to handle Resend OTP;
    forZpin ? resendOtp("Z-PIN") : resendOtp();
    setResendOTPCount(resendOTPCount + 1);
    // Resend Otp to be enabled in
    let resendOTPInSec = 30;
    setTimer(resendOTPInSec);
    const otpCount = setInterval(() => {
      resendOTPInSec -= 1;
      setTimer(resendOTPInSec);
      if (resendOTPInSec === 0) {
        clearInterval(otpCount);
      }
    }, 1000);
    if (resendOTPCount >= 2) {
      setError("You have exhausted the resend OTP limit");
    }
  }
  return (
    <form>
      <div className="py-3 text-center">
        <img
          src={otpVerificationNew}
          alt="otp-verification"
          style={{ width: "100px" }}
        />
      </div>
      <div className="pt-3 pb-1 text-center">
        <p className={`mb-0 ${Styles.headingText}`}>Enter OTP</p>
      </div>
      <div className="py-2 px-4">
        <p className={`mb-0 ${Styles.subText}`}>
          Please enter the OTP sent to <span>{registeredEmail}</span>
        </p>
      </div>
      <div style={{ width: "300px", margin: "0 auto" }}>
        <div className="py-2 d-flex align-items-center justify-content-center">
          {inputs.map((input: any, index: number) => {
            return (
              <div key={input} className="px-1">
                <input
                  ref={(input) => (inputRef.current[index] = input)}
                  id={`otp-verification-${index}`}
                  onClick={() => {
                    setIsInvalid(false);
                    setError("");
                  }}
                  type="password"
                  className={`${Styles.commonInputClass} ${
                    Styles.formControl
                  } ${isInvalid ? Styles.wrongOtp : ""}`}
                  maxLength={1}
                  autoComplete="off"
                  autoFocus={currentActiveInput === index}
                  style={{ width: "40px" }}
                  onKeyUp={(event: any) => {
                    const otpValue = event.target.value;
                    handleOtp(otpValue, index);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="pb-3 px-1 d-flex align-items-center justify-content-center flex-column">
          {error && <ErrorMessage forPopup={true}>{error}</ErrorMessage>}
          {resendOTPCount <= 2 && (
            <div className={`ml-auto mr-auto ${error ? "px-2" : "p-2"}`}>
              {timer > 0 ? (
                <p className={`mb-0 ${Styles.subText}`}>
                  Resend OTP in {timer}s
                </p>
              ) : (
                <p
                  id="resend-otp-verify"
                  className={`mb-0 ${Styles.resendOtpText}`}
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="pt-4 d-flex align-items-center justify-content-center px-4">
        <div className="px-2">
          <Button
            type="submit"
            id="sumbit-otp-verify"
            hoveredStyle={
              validOtp
                ? primaryButtonHoverStylePopup
                : disabledButtonHoverStylePopup
            }
            style={
              validOtp ? primaryButtonStylePopup : disabledButtonStylePopup
            }
            isLoading={isLoading}
            disabled={!validOtp}
            onClick={(e: any) => {
              setTimer(0);
              validateOtp(e);
            }}
            size={MEDIUM}
          >
            Submit
          </Button>
        </div>
      </div>
      <div className="py-3 d-flex justify-content-center">
        <div className="d-inline-flex">
          <p
            id="cancel-otp-verify"
            className={`mb-0 ${Styles.cancelText}`}
            onClick={() => {
              setTimer(0);
              onCancel();
            }}
          >
            Cancel
          </p>
        </div>
      </div>
    </form>
  );
}

export default OtpVerificationPopUp;
