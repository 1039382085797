// Import necessary libraries
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ScriptableContext,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Styles
import Styles from "../dashboard.module.scss";
import { ACCENTCOLOR, SUCCESS } from "../../../../Shared/colors";

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

type dataSet = {
  label: string;
  data: number[];
  backgroundColor: (context: ScriptableContext<"line">) => CanvasGradient;
  borderColor: string;
  fill: boolean;
};

// type
type ComponentProps = {
  graphLabels: string[];
  graphDataSet: dataSet[];
};

const BillableNonBillableChart = ({
  graphLabels,
  graphDataSet,
}: ComponentProps) => {
  // Data for the chart
  const data = {
    labels: graphLabels,
    datasets: graphDataSet,
  };

  // Options for the chart
  const options: any = {
    responsive: true,
    interaction: {
      mode: "point",
    },
    scales: {
      x: {
        stacked: false,
        beginAtZero: false,
        grid: {
          display: true,
        },
      },
      y: {
        stacked: false,
        beginAtZero: false,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "No. of Transactions",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
  };

  return (
    <div className={Styles.barContainer}>
      <Line height={120} data={data} options={options} />
      <div
        className="d-flex justify-content-center font mt-3"
        style={{ columnGap: 30 }}
      >
        <div className="d-flex align-items-center">
          <div
            className={`${Styles.chartLegend}`}
            style={{ backgroundColor: ACCENTCOLOR }}
          />
          <span className={`${Styles.chartLegendText} ml-2`}>Billable</span>
        </div>
        <div className="d-flex align-items-center">
          <div
            className={`${Styles.chartLegend}`}
            style={{ backgroundColor: SUCCESS }}
          />
          <span className={`${Styles.chartLegendText} ml-2`}>Non-Billable</span>
        </div>
      </div>
    </div>
  );
};

export default BillableNonBillableChart;
