import React from "react";

// Styles
import Styles from "./dropdown.module.scss";
import { ACCENTCOLOR, WHITE } from "../colors";

// Constants
import ArrowDown from "../svg/arrowDown";

function Dropdown(props: any) {
  const { default_value, optionsArray, click } = props;
  const [selectedOption, setSelectedOption] = React.useState(default_value);
  return (
    <div className="dropdown">
      <div
        id={props.id}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className={"pl-2"}>
            <p className={`mb-0 ${Styles.dropDownHeader}`}>
              {selectedOption === "" ? default_value : selectedOption}
            </p>
          </div>
          <div className="pl-2">
            <div style={{ cursor: "pointer" }}>
              <ArrowDown color={ACCENTCOLOR} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={"dropdown-menu dropdown-menu-right"}
        style={{
          borderRadius: "8px",
          padding: "10px",
          backgroundColor: WHITE,
          border: 0,
          maxHeight: "300px",
          overflow: "scroll",
          boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.16)",
          zIndex: 1,
        }}
        aria-labelledby="dropdownMenuButton"
      >
        {optionsArray.map((option: any) => {
          return (
            <div
              key={option}
              className={
                selectedOption === option ? Styles.active : Styles.dropdownLink
              }
              id={`dropdown-${option}`}
              onClick={() => {
                setSelectedOption(option);
                click(option);
              }}
            >
              {option}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Dropdown;
