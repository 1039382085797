// Library Utilities
import React from "react";
import { Link } from "react-router-dom";

// styles
import Styles from "./service-info-cards.module.scss";
import { IdsGenerator } from "../../../../utils/idGenerator";

function ServiceInfoCards({
  uniqueId,
  handleServicePopup,
  serviceInfo = {},
  browse,
}: any) {
  function handleBrowseServices() {}
  return (
    <div
      className={`${Styles.serviceInfoCard} d-flex flex-column justify-content-between`}
    >
      {!browse && (
        <>
          <div className="d-flex flex-column">
            <p className="title_bold_text">{serviceInfo.name}</p>
            <p className={Styles.serviceDesc}>
              {serviceInfo.short_description}
            </p>
          </div>
          <div className="d-flex flex-column">
            <p className={`${Styles.avalCreds}`}>Available Trial Credits:</p>
            <p
              className={Styles.creditsInfo}
              id={IdsGenerator({
                prefix: "service-info-card",
                id: "available credits",
                sufix: `${serviceInfo.name}`,
              })}
            >
              {serviceInfo.remaining_test_credits}
            </p>
            <button
              id={IdsGenerator({
                prefix: "service-info-card",
                id: "click",
                sufix: `${serviceInfo.name}`,
              })}
              className={Styles.trialButton}
              onClick={() => handleServicePopup(serviceInfo)}
            >
              Run Trial
            </button>
          </div>
        </>
      )}
      {browse && (
        <Link
          to="/application/product-catalog"
          className={`d-flex flex-column justify-content-center align-items-center ${Styles.browseButtonContainer}`}
        >
          <button
            className={Styles.browseButton}
            id={IdsGenerator({
              prefix: "service-info-card",
              id: "click",
              sufix: "browse all products",
            })}
            onClick={() => handleBrowseServices()}
          >
            +
          </button>
          <p>Browse Products</p>
        </Link>
      )}
    </div>
  );
}

export default ServiceInfoCards;
