import React, { useEffect } from "react";
import Styles from "../../Shared/sharedStyles.module.scss";
import InputField from "../../Shared/inputField/inputField";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Select from "react-select";
import dropDownStyles from "../../../../../Shared/dropDownStyles";
import { rcTaxModeList } from "../../../../../constants/data";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import {
  validChassisNumber,
  validVehicleRegNumber,
} from "../../../../../utils/validation";
import { IdsGenerator } from "../../../../../utils/idGenerator";

function RcPopup({ toastTimeout, handleResponse, name }: any) {
  const serviceName = name.replaceAll(" ", "").toLowerCase();
  const errorObject = {
    vehicleRegNumberError: false,
    chassisNumberError: false,
    rcTaxModeError: false,
    consentError: false,
  };

  const vehicleRegNumberRef = React.useRef<any>("");
  const chassisNumberRef = React.useRef<any>("");

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [rcTaxMode, setRcTaxMode] = React.useState("");
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  // checking vehicle reg number valid or not on change
  function checkVehicleRegistrationNumber() {
    if (
      !validVehicleRegNumber(
        vehicleRegNumberRef.current.value.trim().toUpperCase()
      )
    ) {
      setError({ ...error, vehicleRegNumberError: true });
    } else {
      setError({ ...error, vehicleRegNumberError: false });
    }
  }

  // checking chassis number valid or not on change
  function checkChassisNumber() {
    const chassisNumber = chassisNumberRef.current.value.trim().toUpperCase();
    if (chassisNumber === "" || !validChassisNumber(chassisNumber)) {
      setError({ ...error, chassisNumberError: true });
    } else {
      setError({ ...error, chassisNumberError: false });
    }
  }

  // conditions to check Run verfication button should be enabled or disabled
  useEffect(() => {
    try {
      if (
        vehicleRegNumberRef.current.value.trim() === "" ||
        error.vehicleRegNumberError ||
        (serviceName === "rcrtovehicleverification" &&
          chassisNumberRef.current.value.trim() === "") ||
        error.chassisNumberError ||
        (serviceName === "rcrtovehicleverification" && !rcTaxMode) ||
        error.rcTaxModeError
      ) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } catch (err) {
      setIsDisabled(true);
      toastTimeout(true, "error", (err as Error).message);
    }
  }, [error]);

  function handleCheck() {
    const vehicleRegNumber = vehicleRegNumberRef.current.value.toUpperCase();
    const chassisNumber =
      serviceName === "rcrtovehicleverification"
        ? chassisNumberRef.current.value.toUpperCase()
        : null;
    const consent = isChecked === true ? "Y" : "N";

    if (vehicleRegNumber === "" || !validChassisNumber(vehicleRegNumber)) {
      setError(() => ({
        ...error,
        vehicleRegNumberError: true,
      }));
      return false;
    }
    if (
      serviceName === "rcrtovehicleverification" &&
      (chassisNumber === "" || !validChassisNumber(chassisNumber))
    ) {
      setError(() => ({
        ...error,
        chassisNumberError: true,
      }));
      return false;
    }
    if (serviceName === "rcrtovehicleverification" && !rcTaxMode) {
      setError(() => ({
        ...error,
        rcTaxModeError: true,
      }));
      return false;
    }
    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    if (serviceName === "rcrtovehicleverification") {
      handleResponse(
        { vehicleRegNumber, chassisNumber, rcTaxMode, consent },
        setLoading
      );
    } else handleResponse({ vehicleRegNumber, consent }, setLoading);
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>
              Vehicle Registration Number
            </label>
            <InputField
              id={IdsGenerator({
                prefix: `${name} popup`,
                id: "change",
                sufix: "Vehicle Registration Number",
              })}
              onChange={() => {
                checkVehicleRegistrationNumber();
              }}
              maxLength={10}
              inputRef={vehicleRegNumberRef}
              isError={error.vehicleRegNumberError}
              purpose="forRc"
            />
            {error.vehicleRegNumberError && (
              <ErrorMessage>
                Registration number field is not valid
              </ErrorMessage>
            )}
          </div>
          {serviceName === "rcrtovehicleverification" && (
            <div>
              <div className="d-flex flex-column mb-4 ">
                <label className={`${Styles.label} flex-grow-1`}>
                  Vehicle Chassis Number
                </label>
                <InputField
                  id={IdsGenerator({
                    prefix: `${name} popup`,
                    id: "change",
                    sufix: "Vehicle Chassis Number",
                  })}
                  onChange={() => {
                    checkChassisNumber();
                  }}
                  maxLength={5}
                  inputRef={chassisNumberRef}
                  isError={error.chassisNumberError}
                  purpose="forRc"
                />
                {error.chassisNumberError && (
                  <ErrorMessage>Chassis number field is not valid</ErrorMessage>
                )}
              </div>
              <div className={`${Styles.inputContent} d-flex mt-2 flex-column`}>
                <label className={`${Styles.label}`}>Tax Mode</label>
                <Select
                  id={IdsGenerator({
                    prefix: `${name} popup`,
                    id: "select",
                    sufix: "Tax Mode",
                  })}
                  onChange={(e: any) => {
                    setRcTaxMode(e.value);
                    setError({ ...error, rcTaxModeError: false });
                  }}
                  options={rcTaxModeList}
                  styles={
                    !error.rcTaxModeError
                      ? dropDownStyles.customStylesForTaxMode
                      : dropDownStyles.customStylesForTaxModeError
                  }
                />
              </div>
              {error.rcTaxModeError && (
                <ErrorMessage>Please select Tax mode!</ErrorMessage>
              )}
            </div>
          )}
        </div>
        <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
          <div>
            <input
              id={IdsGenerator({
                prefix: `${name} popup`,
                id: "check",
                sufix: "consent",
              })}
              type="checkbox"
              className="form-check-input"
              onChange={(e: any) => setIsChecked(e.target.checked)}
            />
            <p className={`${Styles.ackText}`}>
              I hearby agree, to let zoop.one verify my data for verification
            </p>
          </div>
          {error.consentError && (
            <ErrorMessage>Please provide consent!</ErrorMessage>
          )}
        </div>
        <div className={`${Styles.buttonGroup}`}>
          <Button
            id={IdsGenerator({
              prefix: `${name} popup`,
              id: "select",
              sufix: "run verification",
            })}
            hoveredStyle={primaryButtonHoverStylePopup}
            disabled={isDisabled}
            size={MEDIUM}
            isLoading={loading}
            style={primaryButtonStylePopup}
            onClick={() => {
              setTimeout(() => setError({ ...errorObject }), 2000);
              handleCheck();
            }}
          >
            Run Verification
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RcPopup;
