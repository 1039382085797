//Confirmation popup if user triggers the app deletion button
import React from "react";
import Styles from "../../../../Shared/popup.module.scss";
import Button from "../../../../Shared/button/button";
import {
  cancelButtonStyle,
  dangerButtonStyle,
  cancelButtonHoverStyle,
  dangerButtonHoverStyle,
} from "../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../Shared/buttonSize";
import { callApi } from "../../../../api/fetch";
import { DANGER } from "../../../../Shared/colors";
import { useHistory } from "react-router-dom";

function DeleteApp({ onCancel, appId }: any) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const history = useHistory();
  function handelCancel(event: any) {
    event.preventDefault();
    onCancel();
    return;
  }
  async function handelDeleteAppAuthentication(event: any) {
    event.preventDefault();
    try {
      setLoading(true);
      await callApi(`/app/${appId}`, {
        method: "DELETE",
      });
      setLoading(false);
      history.push("/application/manage-apps");
    } catch (err) {
      setLoading(false);
      setError((err as Error).message);
    }
    return;
  }
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`}>
        <form>
          <div className="py-4">
            <p className={`mb-0 ${Styles.warningMessage} text-center`}>
              Are you sure you want to Delete this App.
            </p>
          </div>
          <div className="d-flex align-items-center py-3 justify-content-around">
            <div className="px-2">
              <Button
                id={`delete-app-cancel`}
                size={MEDIUM}
                hoveredStyle={cancelButtonHoverStyle}
                style={cancelButtonStyle}
                onClick={handelCancel}
              >
                Cancel
              </Button>
            </div>
            <div className="px-2">
              <Button
                id={`delete-app-continue`}
                size={MEDIUM}
                isLoading={loading}
                hoveredStyle={dangerButtonHoverStyle}
                style={dangerButtonStyle}
                onClick={handelDeleteAppAuthentication}
              >
                Continue
              </Button>
            </div>
          </div>
          {error && (
            <div className="py-2">
              <p
                className="mb-0 text-center"
                style={{ color: DANGER, fontSize: "12px" }}
              >
                {error}
              </p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default DeleteApp;
