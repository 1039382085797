// Library Utilities
import { ScriptableContext } from "chart.js";
import React, { useEffect, useState } from "react";

// Custom components
import CustomDatePicker from "../../../../Shared/custom-date-picker/customDatePicker";
import InputDropdown from "../../../../Shared/input-dropdown/inputDropdown";
import BillableNonBillableChart from "./billableNonBillableChart";

// Functions
import { callApi } from "../../../../api/fetch";
import moment from "moment";

// Constants
import { durationOptions } from "../../../../constants/data";
import {
  ACCENTCOLOR,
  DISABLEDCOLORDARK,
  GRAPHBLUEBORDERCOLOR,
  GRAPHGREENBORDERCOLOR,
  GREYBACKGROUNDCOLOR,
  WHITE,
} from "../../../../Shared/colors";

// Styles
import Styles from "../dashboard.module.scss";

// Types

type Dataset = {
  label: string;
  data: number[];
  backgroundColor: (context: ScriptableContext<"line">) => CanvasGradient;
  borderColor: string;
  fill: boolean;
};

const modeTabs = [
  {
    title: "Production",
    id: "PRODUCTION",
  },
  {
    title: "Test",
    id: "TEST",
  },
];

function ProductsUsage({ productOptions, preSelectedDatePreference }: any) {
  const org_id = localStorage.getItem("organisation_id");
  const user: any = localStorage.getItem("@user");
  const x_user_id = JSON.parse(user)._id;
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [customDurationRange, setCustomDurationRange] = useState({
    from: {
      day: "",
      month: "",
      year: "",
    },
    to: {
      day: "",
      month: "",
      year: "",
    },
  });

  const [selectedDatePreference, setSelectedDatePreference] = useState(
    preSelectedDatePreference
  );
  const [selectedProduct, setSelectedProduct] = useState({
    label: "All Products",
    value: "",
  });

  const [startDate, setStartDate] = useState<any>(
    moment(new Date(moment().subtract(30, "days").calendar())).format(
      "YYYY-MM-DD"
    )
  );
  const [endDate, setEndDate] = useState<any>(moment().format("YYYY-MM-DD"));
  const [currentMode, setCurrentMode] = useState<string>(modeTabs[0].id);

  const [graphLabels, setGraphLabels] = useState<string[]>([]);
  const [graphDataSet, setGraphDataSet] = useState<Dataset[]>([]);

  const getNewGraphData = async () => {
    try {
      const selected_product = selectedProduct.value ?? "";
      const graphRes = await callApi(
        `/organisation/${org_id}?action=get_org_product_usage&${
          selected_product && `arn=${selected_product}&`
        }start_date=${startDate}&end_date=${endDate}&env=${currentMode}`,
        {
          method: "GET",
          init: {
            headers: {
              "x-user-id": x_user_id,
            },
          },
        }
      );

      // Refactoring response according to the graph input
      const labels = getFormattedLabels(graphRes);
      setGraphLabels(labels);
      const dataSet = getTransactionCounts(graphRes);
      setGraphDataSet([
        {
          label: "Billable",
          data: dataSet.billableCounts,
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, "rgba(0, 117, 243, 0.9)");
            gradient.addColorStop(0.5, "rgba(0, 117, 243, 0.5)");
            gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
            return gradient;
          }, // Blue Gradient
          borderColor: GRAPHBLUEBORDERCOLOR, // Blue border
          fill: true,
        },
        {
          label: "Non-Billable",
          data: dataSet.nonBillableCounts,
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, "rgba(15, 157, 88, 0.7)");
            gradient.addColorStop(0.5, "rgba(15, 157, 88, 0.5)");
            gradient.addColorStop(1, "rgba(255,255,255, 0)");
            return gradient; // Green Gradient
          },
          borderColor: GRAPHGREENBORDERCOLOR, // Green border
          fill: true,
        },
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  function getTransactionCounts(data: any) {
    const billableCounts = data?.map((item: any) => item.billable_txn_count);
    const nonBillableCounts = data?.map(
      (item: any) => item.non_billable_txn_count
    );

    return { billableCounts, nonBillableCounts };
  }

  function getFormattedLabels(data: any) {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    return data?.map((item: any) => {
      const dateObj = new Date(item.date);
      const dayOfWeek = daysOfWeek[dateObj.getDay()];
      const month = dateObj.getMonth() + 1;
      const day = dateObj.getDate();
      return `${dayOfWeek} ${month}/${day}`;
    });
  }

  useEffect(() => {
    getNewGraphData();
  }, [selectedProduct, startDate, endDate, org_id, currentMode]);

  useEffect(() => {
    async function getCurrentTransactions() {
      try {
        let start_date, end_date;

        switch (selectedDatePreference) {
          case "Last 7 days": {
            start_date = moment(
              new Date(moment().subtract(7, "days").calendar())
            ).format("YYYY-MM-DD");
            end_date = moment(new Date()).format("YYYY-MM-DD");
            break;
          }
          case "Last 30 days": {
            start_date = moment(
              new Date(moment().subtract(30, "days").calendar())
            ).format("YYYY-MM-DD");
            end_date = moment(new Date()).format("YYYY-MM-DD");
            break;
          }
          case "Last 60 days": {
            start_date = moment(
              new Date(moment().subtract(60, "days").calendar())
            ).format("YYYY-MM-DD");
            end_date = moment(new Date()).format("YYYY-MM-DD");
            break;
          }
          case "Last 120 days": {
            start_date = moment(
              new Date(moment().subtract(120, "days").calendar())
            ).format("YYYY-MM-DD");
            end_date = moment(new Date()).format("YYYY-MM-DD");
            break;
          }
          case "Custom": {
            if (!customDurationRange.from.day && !customDurationRange.to.day)
              return;

            const start_year = parseInt(customDurationRange.from.year);
            const start_month = parseInt(customDurationRange.from.month);
            const start_day = parseInt(customDurationRange.from.day);

            start_date = moment({
              year: start_year,
              month: start_month - 1,
              day: start_day,
            }).format("YYYY-MM-DD");

            const end_year = parseInt(customDurationRange.to.year);
            const end_month = parseInt(customDurationRange.to.month);
            const end_day = parseInt(customDurationRange.to.day);

            end_date = moment({
              year: end_year,
              month: end_month - 1,
              day: end_day,
            }).format("YYYY-MM-DD");
            break;
          }
        }

        setStartDate(moment(start_date).format("YYYY-MM-DD"));
        setEndDate(moment(end_date).format("YYYY-MM-DD"));
      } catch (error) {
        console.log(error);
      }
    }

    // console.log(startDate + " " + endDate);
    // console.log(selectedProduct);
    // console.log(currentMode);

    try {
      if (selectedDatePreference === "Custom" && showCustomDatePicker) return;
      getCurrentTransactions();
    } catch (error) {
      console.log(error);
    }
  }, [selectedDatePreference, customDurationRange]);

  const handleToggle = (mode: string) => {
    setCurrentMode(mode);
  };

  return (
    <>
      {showCustomDatePicker && (
        <CustomDatePicker
          setShowCustomDatePicker={setShowCustomDatePicker}
          maxDateDifference={120}
          setCustomDurationRange={(data: any) => {
            setCustomDurationRange(data);
            setShowCustomDatePicker(false);
          }}
        />
      )}
      <div className={`${Styles.card} d-flex flex-column pt-3 pl-4 pr-4 mb-2`}>
        <div className="d-flex flex-column mr-5">
          <div className="d-flex align-items-center">
            <p className="text-dark font-weight-bold">Product Usage</p>
          </div>
          <div className="d-flex mb-2" style={{ columnGap: 10, width: "100%" }}>
            <InputDropdown
              id="product-usage-all-products"
              forDashboard={true}
              style={{ width: "10px" }}
              no_shadow="true"
              optionsArray={productOptions}
              placeholder={"All Products"}
              borderRadius="true"
              click={(value: any) => {
                setSelectedProduct(value);
              }}
            />
            <InputDropdown
              id="product-usage-select-duration"
              parentCardName="product-usage"
              no_shadow="true"
              forDashboard={true}
              borderRadius="true"
              placeholder={preSelectedDatePreference}
              optionsArray={durationOptions}
              click={(value: any) => {
                setSelectedDatePreference(value);
                if (value === "Custom") {
                  setShowCustomDatePicker(true);
                }
              }}
              resetSelection={() => {}}
            />
            <div
              className="d-flex flex-row flex-grow-1 mr-3 mb-2"
              style={{
                height: "100%",
                backgroundColor: GREYBACKGROUNDCOLOR,
                borderRadius: 10,
                padding: 4,
                display: "flex",
                flexDirection: "row",
              }}
            >
              {modeTabs.map((tab) => {
                const isSelected = tab.id == currentMode;
                const backgroundColor = isSelected
                  ? WHITE
                  : GREYBACKGROUNDCOLOR;
                const textColor = isSelected ? ACCENTCOLOR : DISABLEDCOLORDARK;
                return (
                  <button
                    id={`product-usage-select-${tab.title}`}
                    key={tab.id}
                    onClick={() => {
                      handleToggle(tab.id);
                    }}
                    style={{
                      backgroundColor,
                      color: textColor,
                      width: 100,
                      fontSize: 14,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 6,
                      outline: "none",
                      border: "none",
                    }}
                  >
                    {tab.title}
                  </button>
                );
              })}
            </div>
          </div>

          <BillableNonBillableChart
            graphLabels={graphLabels}
            graphDataSet={graphDataSet}
          />
        </div>
      </div>
    </>
  );
}

export default ProductsUsage;
