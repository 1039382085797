import React from "react";

// Constants
import { Link } from "react-router-dom";

// Styles
import Styles from "./multipurposeCard.module.scss";

function CardDetails(props: any) {
  return (
    <div className=" d-flex h-100 flex-column justify-content-around p-2">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column flex-wrap">
          <span className={Styles.cardTitle}>{props.title.split(" ")[0]}</span>
          <span className={Styles.cardTitle}>{props.title.split(" ")[1]}</span>
        </div>
        <span>{props.img}</span>
      </div>

      <div className="d-flex flex-column mt-4">
        <span className={`${Styles.boldTitle} mb-2`}>{props.value}</span>
        {props.subtitle?.length > 0 && (
          <span className={Styles.subTitle}>{props.subtitle}</span>
        )}
      </div>

      <div className="mt-4">
        <Link
          to={props.linkPath}
          className={"d-flex align-items-center text-decoration-none"}
          id={props.id}
        >
          {`${props.linkTitle} ➔`}
        </Link>
      </div>
    </div>
  );
}

export default CardDetails;
