// Library Utilities
import moment from "moment";

// Hooks
import React, { useState, useEffect } from "react";

// styles
import Styles from "./transactionStats.module.scss";
import InputDropDownStyles from "../../../../Shared/input-dropdown/inputDropdown.module.scss";

// components
import CustomDatePicker from "../../../../Shared/custom-date-picker/customDatePicker";
import InputDropdown from "../../../../Shared/input-dropdown/inputDropdown";
import DataTable from "../../../../Shared/data-table/datatable";
import Loading from "../../../../Shared/loading/loading";

// constants
import { durationOptions } from "../../../../constants/data";
import { envTypes } from "../../../../constants/envTypes";
import {
  ACCENTCOLOR,
  DISABLEDCOLORDARK,
  GREYBACKGROUNDCOLOR,
  WHITE,
} from "../../../../Shared/colors";

// functions
import { callApi } from "../../../../api/fetch";
import { addLeadingZero } from "../../../../utils/date-format";

const modeTabs = [
  {
    title: "Production",
    id: "PRODUCTION",
  },
  {
    title: "Test",
    id: "TEST",
  },
];

const tableHeader = [
  {
    name: "Product Name",
    className: Styles.name,
  },
  {
    name: "Balance Credits",
    className: Styles.name,
  },
  {
    name: "Billable Transactions",
    className: Styles.transNo,
  },
  {
    name: "Success",
    className: Styles.status,
  },
  {
    name: "Failed",
    className: Styles.resCode,
  },
];

const preSelectedDatePreference = "Last 30 days";

function TransactionStats({ appDetails, appData }: any) {
  const [loading, setLoading] = useState(false);
  const org_id = localStorage.getItem("organisation_id");
  const user: any = localStorage.getItem("@user");
  const x_user_id = JSON.parse(user)._id;
  const [activePageNumber, setActivePageNumber] = React.useState(1);

  const [totalNumberOfPages, setTotalNumberOfPages] = React.useState(0);
  const [selectedAppId, setSelectedAppId] = React.useState(appDetails?._id);
  const selectedApp = React.useMemo(() => {
    const app = appData.find((app: any) => app._id === selectedAppId);
    return app;
  }, [selectedAppId]);
  const currentMode = React.useMemo(() => {
    const testApp = selectedApp.is_test_app;
    return testApp;
  }, [selectedAppId]);

  const appOptions = React.useMemo(() => {
    return appData.map((app: any, idx: number) => ({
      label: app.name,
      value: app._id,
    }));
  }, [appData]);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [transactionStats, setTransactionStats] = useState<any>([]);
  const [customDurationRange, setCustomDurationRange] = useState({
    to: {
      day: 0,
      month: 0,
      year: 0,
    },
    from: {
      day: 0,
      month: 0,
      year: 0,
    },
  });
  const [selectedDatePreference, setSelectedDatePreference] = useState(
    preSelectedDatePreference
  );

  const [startDate, setStartDate] = useState<string | null>(
    moment(new Date(moment().subtract(30, "days").calendar())).format(
      "YYYY-MM-DD"
    )
  );
  const [endDate, setEndDate] = useState<string | null>(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const tableHeaders = React.useMemo(() => {
    if (selectedApp?.is_test_app) {
      return tableHeader;
    }

    return tableHeader.filter((header) => header.name !== "Balance Credits");
  }, [selectedApp?.is_test_app]);

  const tableHeaderCustom = () => {
    return (
      <div className="d-flex flex-column flex-wrap justify-content-between pt-3 mb-2">
        <p className={"mb-4 text-dark font-weight-bold"}>Transaction Stats</p>
        <div className="d-flex mb-3" style={{ columnGap: 10, width: "100%" }}>
          <InputDropdown
            id="trasaction-stats-select-duration"
            parentCardName="trasaction-stats"
            labelname="Duration"
            placeholder={preSelectedDatePreference}
            forDashboard={true}
            optionsArray={durationOptions}
            style={{ width: "10px" }}
            no_shadow="true"
            borderRadius="true"
            click={(value: any) => {
              setSelectedDatePreference(value);
              if (value === "Custom") {
                setShowCustomDatePicker(true);
              }
            }}
            resetSelection={() => {}}
          />

          <InputDropdown
            id="trasaction-stats-select-app-name"
            labelname="App Name"
            placeholder={selectedApp.name}
            forDashboard={true}
            optionsArray={appOptions}
            style={{ width: "10px" }}
            no_shadow="true"
            borderRadius="true"
            click={(option: any) => {
              setSelectedAppId(option.value);
            }}
          />

          <div>
            <p className={`${InputDropDownStyles.formLabel} mb-2`}>
              Environment
            </p>
            <div
              className="d-flex flex-row flex-grow-1 mr-3 mb-2"
              style={{
                // height: "100%",
                backgroundColor: GREYBACKGROUNDCOLOR,
                borderRadius: 10,
                padding: 5,
                display: "flex",
                flexDirection: "row",
              }}
            >
              {modeTabs.map((tab) => {
                const isSelectedApp = currentMode ? "TEST" : "PRODUCTION";
                const backgroundColor =
                  isSelectedApp === tab.id ? WHITE : GREYBACKGROUNDCOLOR;
                const textColor =
                  isSelectedApp === tab.id ? ACCENTCOLOR : DISABLEDCOLORDARK;

                return (
                  <button
                    key={tab.id}
                    style={{
                      backgroundColor,
                      color: textColor,
                      width: 100,
                      padding: 6,
                      fontSize: 14,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 6,
                      outline: "none",
                      border: "none",
                    }}
                  >
                    {tab.title}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Memoize the calculation of start and end dates
  const memoizedDateRange = React.useMemo(() => {
    let start_date: string | null = null;
    let end_date: string | null = moment(new Date()).format("YYYY-MM-DD");

    switch (selectedDatePreference) {
      case "Last 7 days": {
        start_date = moment(
          new Date(moment().subtract(7, "days").calendar())
        ).format("YYYY-MM-DD");
        break;
      }
      case "Last 30 days": {
        start_date = moment(
          new Date(moment().subtract(30, "days").calendar())
        ).format("YYYY-MM-DD");
        break;
      }
      case "Last 60 days": {
        start_date = moment(
          new Date(moment().subtract(60, "days").calendar())
        ).format("YYYY-MM-DD");
        break;
      }
      case "Last 120 days": {
        start_date = moment(
          new Date(moment().subtract(120, "days").calendar())
        ).format("YYYY-MM-DD");
        break;
      }
      case "Custom": {
        if (!customDurationRange.from.day && !customDurationRange.to.day)
          return { start_date: null, end_date: null };

        start_date = `${customDurationRange.from.year}-${addLeadingZero(
          customDurationRange.from.month
        )}-${addLeadingZero(customDurationRange.from.day)}`;

        end_date = `${customDurationRange.to.year}-${addLeadingZero(
          customDurationRange.to.month
        )}-${addLeadingZero(customDurationRange.to.day)}`;
        break;
      }
    }

    return { start_date, end_date };
  }, [selectedDatePreference, customDurationRange]);

  useEffect(() => {
    setStartDate(memoizedDateRange.start_date);
    setEndDate(memoizedDateRange.end_date);
  }, [memoizedDateRange]);

  async function getTransactionStats() {
    const url = `/organisation/${org_id}?action=get_org_transaction_stats&app_id=${
      selectedApp?._id
    }&env=${
      selectedApp[0]?.is_test_app ? envTypes.TEST : envTypes.PRODUCTION
    }&start_date=${startDate}&end_date=${endDate}`;

    setActivePageNumber(1);
    try {
      setLoading(true);
      const res = await callApi(url, {
        method: "GET",
        init: {
          headers: {
            "x-user-id": x_user_id,
          },
        },
      });

      setLoading(false);
      setTransactionStats(res);
    } catch (err) {
      console.log(err);
    }

    return;
  }

  useEffect(() => {
    if (selectedDatePreference === "Custom" && showCustomDatePicker) return;

    getTransactionStats();
  }, [startDate, endDate, selectedApp, currentMode]);

  return (
    <>
      {showCustomDatePicker && (
        <CustomDatePicker
          setShowCustomDatePicker={setShowCustomDatePicker}
          maxDateDifference={120}
          setCustomDurationRange={(data: any) => {
            setCustomDurationRange(data);
            setShowCustomDatePicker(false);
          }}
        />
      )}
      <div>
        <DataTable
          headers={tableHeaders}
          loading={loading}
          rows={transactionStats ? transactionStats : ""}
          setActivePageNumber={setActivePageNumber}
          totalNumberOfPages={totalNumberOfPages}
          tableHeader={tableHeaderCustom()}
          bodyClassName={Styles?.bodyClassName}
          purpose="forDashboard"
        >
          {loading ? (
            <Loading />
          ) : (
            transactionStats?.length > 0 &&
            transactionStats.map((traxn: any, index: number) => {
              return (
                <tr key={index} className="px-1" style={{ height: 65 }}>
                  <td className="px-4 py-2 my-4">
                    <p className={"mb-0 body_small_text"}>
                      {traxn.product_name}
                    </p>
                  </td>

                  {selectedApp.is_test_app && (
                    <td className="px-4 py-2">
                      <p className={"mb-0 body_small_text"}>
                        {traxn.test_credits}
                      </p>
                    </td>
                  )}
                  <td className="px-4 py-2">
                    <p className={"mb-0 body_small_text"}>
                      {traxn.billable_txn_count}
                    </p>
                  </td>
                  <td className="px-4 py-2">
                    <p className={"mb-0 body_small_text"}>
                      {traxn.success_txn_count}
                    </p>
                  </td>
                  <td className="px-4 py-2">
                    <p className={"mb-0 body_small_text"}>
                      {traxn.failure_txn_count}
                    </p>
                  </td>
                </tr>
              );
            })
          )}
        </DataTable>
      </div>
    </>
  );
}

export default TransactionStats;
