// Library Utilities
import React from "react";
import moment from "moment";

// components
import Button from "../../../../Shared/button/button";

// Constants
import { MEDIUM } from "../../../../Shared/buttonSize";
import DownloadSvg from "../../../../assets/images/download";
import PauseSvg from "../../../../assets/images/stop-icon";
import { ReactComponent as CopySvg } from "../../../../assets/images/copy.svg";

// Styles
import styles from "./trackTask.module.scss";
import {
  disabledButtonStyle,
  borderButtonStyle,
  blueHoverButtonStyle,
} from "../../../../Shared/buttonStyles";

interface reponseCodeCount {
  response_code?: string;
  count?: any;
  billable?: "Y" | "N";
}

interface TrackDataObject {
  batchId: string;
  batchName: string | null;
  batchState: string;
  batchType: string;
  created_at: string;
  destinationMode: string;
  downloadInitiated: string | null;
  downloadUrl: string | null;
  orgId: string;
  orgName: string | null;
  totalItems: number;
  totalProcessed: number;
  tpm: any;
  updated_at: string;
  uploadSource: string | null;
  userEmail: string | null;
  successCount: reponseCodeCount[];
}

interface ProgressData {
  item: TrackDataObject;
  executeAction: Function;
  btndisable: boolean;
  key: any;
  loading: any;
}

const ProgressCard = ({
  item,
  btndisable,
  loading,
  executeAction,
  key,
}: ProgressData) => {
  const [copiedText, setCopiedText] = React.useState<Boolean>(false);

  const useDynamicHover = () => {
    const [hoveredItems, setHoveredItems] = React.useState<{
      [key: string]: boolean;
    }>({});

    const handleMouseEnter = (key: string) => {
      setHoveredItems((prev: any) => ({ ...prev, [key]: true }));
    };

    const handleMouseLeave = (key: string) => {
      setHoveredItems((prev: any) => ({ ...prev, [key]: false }));
    };

    return { hoveredItems, handleMouseEnter, handleMouseLeave };
  };

  const {
    hoveredItems,
    handleMouseEnter,
    handleMouseLeave,
  } = useDynamicHover();

  // To get the percentage of task
  function percentage(partialValue: number, totalValue: number) {
    const percent = (100 * partialValue) / totalValue;
    return percent.toFixed(2);
  }
  const waitingForApproval = () => {
    return (
      <div className={styles.waitingForApproval}>Waiting For Approval </div>
    );
  };

  async function copyBatchId(batchId: string) {
    try {
      if (batchId) {
        await navigator.clipboard.writeText(batchId);
        setCopiedText(true);
      }
      // setCopiedText(true);
    } catch (error) {
      console.error(error);
    }
  }

  const stopBtn = (data: any) => {
    const batchPercentage = percentage(
      data?.total_completed || 0,
      data?.total_items || 0
    );
    const disable =
      Number(batchPercentage) > 90 &&
      (data.status === "RUNNING" || data.status === "PAUSED");

    return (
      <div
        className={`${styles.actionButton} ${styles.stopBtn}
           ${!disable ? styles.disabled : ""}
          `}
        id="progress-card-stop-task"
        onClick={() => disable && executeAction(data, "STOP_TASK")}
      >
        <div
          className="d-flex align-items-center h-100 justify-content-center"
          id="progress-card-pause-task"
          onMouseEnter={() => handleMouseEnter("isPause")}
          onMouseLeave={() => handleMouseLeave("isPause")}
        >
          <PauseSvg
            fillBackgroundColor={
              hoveredItems["isPause"]
                ? "#DB4437"
                : !disable
                ? "#DDDCDD"
                : "#FFFFFF"
            }
            InnerColor={
              hoveredItems["isPause"]
                ? "#FFFFFF"
                : !disable
                ? "#3b3b3b"
                : "#DB4437"
            }
            border={
              hoveredItems["isPause"]
                ? "#FFFFFF"
                : !disable
                ? "#fffff"
                : "#DB4437"
            }
          />
        </div>
      </div>
    );
  };

  return (
    <tr className={styles.progressCardRow}>
      <td
        style={{
          borderLeft:
            item?.batchState === "ERROR"
              ? "6px solid #db4437"
              : item?.batchState === "FINISHED"
              ? "6px solid #0f9d58"
              : item?.batchState === "PROGRESS"
              ? "6px solid #7F00FF"
              : "6px solid #ffc922",
        }}
      >
        <div className={styles.progressBlock + " pl-1"}>
          <div className={styles.batchName}>{item.batchName}</div>

          <div className="d-flex">
            <div className="mr-2">ID: </div>
            <div className={styles.batchIdValue}>{item?.batchId}</div>
            <p
              className={styles.copyButton}
              style={{
                height: "30px",
                cursor: "pointer",
              }}
            >
              <CopySvg
                id="progress-card-copy-batchId"
                onClick={() => copyBatchId(item.batchId)}
              />
              <p className={styles.copyText}>
                {copiedText ? "Copied" : "Copy text"}
              </p>
            </p>
          </div>

          <div className="d-flex">
            <div className="mr-2">ARN: </div>
            <div className={styles.value}>{item?.batchType}</div>
          </div>
          <div className="d-flex">
            <div className="mr-2">Created at: </div>
            <div className={styles.value}>
              {/* <strong className={trackTaskStyle.value}> */}
              {moment(item?.created_at).format("DD/MM/YYYY, HH:mm:ss")}
              {/* </strong> */}
            </div>
          </div>
          <div className="d-flex">
            <div className="mr-2">Updated At: </div>
            <div className={styles.value}>
              {moment(item?.updated_at).format("DD/MM/YYYY, HH:mm:ss")}
            </div>
          </div>
        </div>
      </td>
      <td>
        {" "}
        <div className={styles.borderGap} />
      </td>
      <td className={styles.progressColumn}>
        <div
          className={item.batchState === "PAUSED" ? styles.progressDisable : ""}
        >
          {item.batchState === "Waiting for approval" ? (
            waitingForApproval()
          ) : (
            <>
              {/* <div className="d-flex justify-content-center"> */}
              <div className={styles.progressHead}>Progress:</div>
              {/* <div className={styles.progressAdjustment}> */}
              <progress
                id="file"
                value={item?.totalProcessed}
                max={item?.totalItems}
                style={{ width: "75%" }}
              ></progress>
              <span className="ml-2">
                {percentage(item?.totalProcessed || 0, item?.totalItems || 0)}%
              </span>
              {/* </div> */}

              {/* </div> */}
              <div>
                {item?.batchState === "ERROR" ? (
                  <>
                    <div className={styles.errorBox}>ERROR</div>
                  </>
                ) : item?.batchState === "FINISHED" ? (
                  <div className={styles.finishedBox}>FINISHED</div>
                ) : item?.batchState === "PROGRESS" ? (
                  <div className={styles.progressBox}>PROGRESS</div>
                ) : (
                  <div className={styles.pausedBox}>{item?.batchState}</div>
                )}
              </div>
            </>
          )}
        </div>
      </td>
      <td>
        <div className={styles.borderGap} />
      </td>
      <td>
        <div>{stopBtn(item.batchId)}</div>
      </td>
      <td>
        {item?.downloadUrl !== null && item?.downloadUrl.length ? (
          <a href={item?.downloadUrl || ""} className="w-50" download>
            <Button
              id="z-track-task-btn"
              size={MEDIUM}
              hoveredStyle={
                !btndisable ? blueHoverButtonStyle : disabledButtonStyle
              }
              style={!btndisable ? borderButtonStyle : disabledButtonStyle}
              isLoading={loading}
              className={
                styles.downloadBtn +
                " " +
                (!btndisable ? "" : styles.disableBtn)
              }
              onMouseEnter={() => handleMouseEnter("isDownload")}
              onMouseLeave={() => handleMouseLeave("isDownload")}
            >
              <span className="mr-2">Download</span>
              <DownloadSvg
                fillBackgroundColor={
                  hoveredItems["isDownload"] ? "#fff" : "#3272EC"
                }
              />
            </Button>
          </a>
        ) : (
          <div className="w-50">
            <Button
              id="z-generate-link-tracktask"
              size={MEDIUM}
              style={disabledButtonStyle}
              disabled={
                item.batchState !== "FINISHED" || item?.downloadInitiated
              }
              hoveredStyle={false}
              className={styles.downloadBtn}
            >
              <span className="mr-2">Download</span>
              <DownloadSvg fillBackgroundColor={"#969696"} />
            </Button>
          </div>
        )}
      </td>
    </tr>
  );
};

export default ProgressCard;
