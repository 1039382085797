import React from "react";
import { addGST } from "../../../../utils/updateAmpunt";

// Components
import CardDetails from "./cardDetails";

// Constants
import SubscriptionSVG from "../../../../Shared/svg/subscriptionSVG";
import RupeeSVGnew from "../../../../Shared/svg/rupeeSVGnew";
import TickSVGnew from "../../../../Shared/svg/tickSVGnew";
import BargraphSVG from "../../../../Shared/svg/bargraphSVG";

// Import Styles
import Styles from "./multipurposeCard.module.scss";

function MultipurposeCard(props: any) {
  return (
    <div className={`${Styles.card} ml-1 mb-2 mr-1 p-2`}>
      {props.purpose === "trans_info" && (
        <CardDetails
          title={"Transaction Volume"}
          img={<BargraphSVG />}
          value={props.tranxnVolume}
          subtitle={"till date"}
          linkTitle={"View Reports"}
          linkPath={"/application/reports"}
          id={"z-link-to-transaction-volume"}
        />
      )}
      {props.purpose === "subscription_info" && (
        <CardDetails
          title={"Products Subscribed"}
          img={<SubscriptionSVG />}
          value={props.productCount}
          subtitle={""}
          linkTitle={"More Products"}
          linkPath={"/application/product-catalog"}
          id={"z-link-to-products-subscribed"}
        />
      )}
      {props.purpose === "avl_balance_info" && (
        <CardDetails
          title={"Available Balance"}
          img={<RupeeSVGnew />}
          value={
            isNaN(props.availableBalance)
              ? "NA"
              : addGST(parseFloat(props.availableBalance))
          }
          subtitle={""}
          linkTitle={"View Plans"}
          linkPath={"/application/billing"}
          id={"z-link-to-available-balance"}
        />
      )}
      {props.purpose === "trial_info" && (
        <CardDetails
          title={"Trial Approved"}
          img={<TickSVGnew />}
          value={props.latestApprovedProd}
          subtitle={""}
          linkTitle={"Begin Trial"}
          linkPath={"/application/trial-center"}
          id={"z-link-to-trial-approved"}
        />
      )}
    </div>
  );
}

export default MultipurposeCard;
