import React from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import { validAadhaarNumber } from "../../../../../utils/validation";
import { IdsGenerator } from "../../../../../utils/idGenerator";

function AadhaarProPopup({ handleResponse, name }: any) {
  const errorObject = {
    aadhaarNumberError: false,
    consentError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [panProChecked, setPanProChecked] = React.useState(false);

  const aadhaarNumberRef = React.useRef<any>("");

  function isValid() {
    if (validAadhaarNumber(aadhaarNumberRef.current.value.trim())) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    setError(() => ({
      ...error,
      aadhaarNumberError: true,
    }));
    return setIsDisabled(true);
  }
  const handleChange = (event: any) => {
    setPanProChecked(event.target.value === "true");
  };

  function handleCheck() {
    const customer_aadhaar_number = aadhaarNumberRef.current.value;
    const consent = isChecked === true ? "Y" : "N";
    const pan_details = panProChecked;

    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    handleResponse(
      { customer_aadhaar_number, pan_details, consent },
      setLoading
    );
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>
              AADHAAR Number
            </label>
            <InputField
              id={IdsGenerator({
                prefix: "aadhaar pro popup",
                id: "change",
                sufix: "aadhaar number",
              })}
              onChange={isValid}
              maxLength={12}
              inputRef={aadhaarNumberRef}
              isError={error.aadhaarNumberError}
              purpose="forPan"
            />
            {error.aadhaarNumberError && (
              <ErrorMessage>Please enter a valid Aadhaar number</ErrorMessage>
            )}
          </div>
          <>
            <label className={`${Styles.label} flex-grow-1 mt-4`}>
              is Pan Pro required ??
            </label>
            <div className="d-flex mt-1">
              <label className="mr-2 mb-0">
                <input
                  id={IdsGenerator({
                    prefix: "aadhaar pro popup",
                    id: "select true",
                    sufix: "pan pro required",
                  })}
                  type="radio"
                  value="true"
                  checked={panProChecked === true}
                  onChange={handleChange}
                  className="mr-1"
                />
                True
              </label>

              <label className="mb-0">
                <input
                  id={IdsGenerator({
                    prefix: "aadhaar pro popup",
                    id: "select false",
                    sufix: "pan pro required",
                  })}
                  type="radio"
                  value="false"
                  checked={panProChecked === false}
                  onChange={handleChange}
                  className="mr-1"
                />
                False
              </label>
            </div>
          </>
          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                id={IdsGenerator({
                  prefix: "aadhaar pro popup",
                  id: "check",
                  sufix: "consent",
                })}
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => {
                  setIsChecked(e.target.checked);
                  isValid();
                }}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              // id="id-submit-button-aadhaar"
              id={IdsGenerator({
                prefix: "aadhaar pro popup",
                id: "click",
                sufix: "run verification",
              })}
              s
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AadhaarProPopup;
