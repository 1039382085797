import React, { useEffect } from "react";
import Select from "react-select";

// Components
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";

// Styles
import Styles from "../../Shared/sharedStyles.module.scss";
import dropDownStyles from "../../../../../Shared/dropDownStyles";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";

// Constants
import { days, months } from "../../../../../constants/data";
import { MEDIUM } from "../../../../../Shared/buttonSize";

// Functions
import {
  validCustomerFileNumber,
  validNameCheck,
} from "../../../../../utils/validation";
import { getYears } from "../../../../../utils/getYears";
import { IdsGenerator } from "../../../../../utils/idGenerator";

function PassportAdvanceServicePopup({ handleResponse, name }: any) {
  const dateRef = React.useRef<any>();
  const monthRef = React.useRef<any>();
  const yearRef = React.useRef<any>();

  const [day, setDay] = React.useState("");
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");
  const nameMatchRef = React.useRef<any>("");
  const [isNameMatchEnabled, setIsNameMatchEnabled] = React.useState(false);

  const errorObject = {
    customerFileNumberError: false,
    consentError: false,
    dobDayError: false,
    dobMonthError: false,
    dobYearError: false,
    nameToMatchError: false,
  };
  const yearOptions = getYears(1950);
  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const customerFileNumberRef = React.useRef<any>("");

  useEffect(() => {
    if (isNameMatchEnabled) {
      setIsDisabled(true);
    }
    if (!isNameMatchEnabled) {
      if (customerFileNumberRef.current.value && year && day && month) {
        setIsDisabled(false);
      }
    }
  }, [isNameMatchEnabled]);

  useEffect(() => {
    if (
      customerFileNumberRef.current.value &&
      year &&
      day &&
      month &&
      !isNameMatchEnabled
    ) {
      setIsDisabled(false);
    }
  }, [customerFileNumberRef, year, day, month]);

  useEffect(() => {
    if (isNameMatchEnabled) {
      if (
        customerFileNumberRef.current.value &&
        year &&
        day &&
        month &&
        nameMatchRef.current.value
      ) {
        setIsDisabled(false);
      }
    }
  }, [customerFileNumberRef, year, day, month, isNameMatchEnabled]);

  function isValid() {
    setError({ ...errorObject });
    const customerFileNumber = customerFileNumberRef.current.value
      .trim()
      .toUpperCase();
    if (validCustomerFileNumber(customerFileNumber) && day && month && year) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    if (!validCustomerFileNumber(customerFileNumber)) {
      setError(() => ({
        ...error,
        customerFileNumberError: true,
      }));
    }
    return setIsDisabled(true);
  }

  function isValidNameMatch() {
    setError({ ...errorObject });
    const customerFileNumber = customerFileNumberRef.current.value
      .trim()
      .toUpperCase();
    if (
      validCustomerFileNumber(customerFileNumber) &&
      validNameCheck(nameMatchRef.current.value) &&
      day &&
      month &&
      year
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    if (!validCustomerFileNumber(customerFileNumber)) {
      setError(() => ({
        ...error,
        customerFileNumberError: true,
      }));
    }
    if (!validNameCheck(nameMatchRef.current.value)) {
      setError(() => ({
        ...error,
        nameToMatchError: true,
      }));
    }
    return setIsDisabled(true);
  }

  function handleCheck() {
    const customerFileNumber = customerFileNumberRef.current.value.toUpperCase();
    const consent = isChecked === true ? "Y" : "N";
    const dob = `${day}-${month}-${year}`;
    if (isNameMatchEnabled && nameMatchRef.current.value === "") {
      setError(() => ({
        ...error,
        nameToMatchError: true,
      }));
      return setIsDisabled(true);
    }
    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    if (nameMatchRef && nameMatchRef.current && nameMatchRef.current.value) {
      const nameToMatch = nameMatchRef.current.value;
      handleResponse(
        { customerFileNumber, nameToMatch, dob, consent },
        setLoading
      );
    } else if (!isNameMatchEnabled) {
      handleResponse({ customerFileNumber, dob, consent }, setLoading);
    }
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>
              Customer File Number
            </label>
            <InputField
              id={IdsGenerator({
                prefix: "passport advance popup",
                id: "change",
                sufix: "customer file number",
              })}
              onChange={isValid}
              minLenght={12}
              maxLength={15}
              inputRef={customerFileNumberRef}
              isError={error.customerFileNumberError}
              purpose="forPassport"
            />
            {error.customerFileNumberError && (
              <ErrorMessage>
                Please enter a valid Customer file number
              </ErrorMessage>
            )}
          </div>

          <label className={`${Styles.label}`}>DOB*</label>
          <div className="d-flex mr-1">
            <div className="mr-2 d-flex">
              <div className="d-flex flex-column">
                <Select
                  id={IdsGenerator({
                    prefix: "passport advance popup",
                    id: "select",
                    sufix: "date",
                  })}
                  ref={dateRef}
                  onChange={(e: any) => setDay(e.value)}
                  options={days}
                  styles={
                    !error.dobDayError
                      ? dropDownStyles.customStylesForDays
                      : dropDownStyles.customStylesForDaysError
                  }
                  placeholder="DD"
                />
              </div>
              <div className="ml-2 d-flex flex-column">
                <Select
                  id={IdsGenerator({
                    prefix: "passport advance popup",
                    id: "select",
                    sufix: "month",
                  })}
                  onChange={(e: any) => setMonth(e.value)}
                  ref={monthRef}
                  options={months}
                  styles={
                    !error.dobMonthError
                      ? dropDownStyles.customStylesForMonths
                      : dropDownStyles.customStylesForMonthsError
                  }
                  placeholder="MMM"
                />
              </div>
              <div className="ml-2 d-flex flex-column">
                <Select
                  id={IdsGenerator({
                    prefix: "passport advance popup",
                    id: "select",
                    sufix: "year",
                  })}
                  onChange={(e: any) => setYear(e.value)}
                  ref={yearRef}
                  placeholder="YYYY"
                  options={yearOptions}
                  styles={
                    !error.dobYearError
                      ? dropDownStyles.customStylesForYears
                      : dropDownStyles.customStylesForYearsError
                  }
                />
              </div>
            </div>
          </div>
          <div className="pl-4 pb-2 pt-4 d-flex flex-column">
            <div>
              <input
                id={IdsGenerator({
                  prefix: "passport advance popup",
                  id: "check",
                  sufix: "is name match required",
                })}
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => setIsNameMatchEnabled(e.target.checked)}
              />
              <p className={`${Styles.ackText}`}>is name match required ?</p>
            </div>
          </div>
          {isNameMatchEnabled && (
            <div className="d-flex flex-column mb-4 ">
              <label className={`${Styles.label} flex-grow-1`}>
                Name To Match
              </label>
              <InputField
                id={IdsGenerator({
                  prefix: "passport advance popup",
                  id: "change",
                  sufix: "is name match",
                })}
                onChange={isValidNameMatch}
                inputRef={nameMatchRef}
                isError={error.nameToMatchError}
                purpose="forPassport"
                text="lowerCase"
              />
              {error.nameToMatchError && (
                <ErrorMessage>Please enter a valid Name</ErrorMessage>
              )}
            </div>
          )}

          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                id={IdsGenerator({
                  prefix: "passport advance popup",
                  id: "check",
                  sufix: "consent",
                })}
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => {
                  setIsChecked(e.target.checked);
                  isNameMatchEnabled ? isValidNameMatch() : isValid();
                }}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id={IdsGenerator({
                prefix: "passport advance popup",
                id: "click",
                sufix: "run verification",
              })}
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PassportAdvanceServicePopup;
